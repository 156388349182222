<template>
   <Box class="progress_card is-clickable py-3 px-3 is-shadowless is-radiusless" :style="{'--bkgColor': 'hsl(48, 100%, 67%)' }">
      <div class="block">
        <project-name :id="item.project_id" />
        <h3 class="progress_card__title has-text-weight-semibold title is-size-6 mb-2 mt-1" @click="$emit('titleClickHandle')">
          {{ item.title }}
        </h3>
      </div>
      <div class="block">

        <div class="mt-3">
          <p>
            <small class="is-size-7">Spent: </small>
            <span class="has-text-weight-medium is-size-7">{{ item.time_stats.human_total_time_spent ?  item.time_stats.human_total_time_spent: 'N/A' }}</span>
          </p>
        </div>
      </div>
      <div class="block">
          <div v-if="item.assignees.length > 0" class="is-flex is-align-items-center">
            <figure class="image is-24x24 mr-2">
              <img class="is-rounded" :src="item.assignees[0].avatar_url">
            </figure>
            <div class="has-text-weight-medium is-size-7">{{ item.assignees[0].name }}</div>
          </div>
      </div>
    </Box>
</template>

<script>
   import { defineAsyncComponent } from "vue";
  export default {
    components: {
      Box: defineAsyncComponent(() => import('@/components/ds/Box')),
      ProjectName: defineAsyncComponent(() => import('@/components/widgets/taskProgress/projectName')),
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .progress_card{
    border-bottom: 1px solid  #f5f5f5;

    &__title {
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: #f5f5f5;
    }
  }
</style>